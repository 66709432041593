@import "base";
@import "app";
@import "dx";

.wrapper {
    margin: 0 auto;
    max-width: 1024px;
    min-width: 320px;
    width: 100%;
}  
