@import "scrollbar";


$base-size: 60px;
$app-width: 17 * $base-size;
$app-height: 12 * $base-size;

$view-size: 480px;
$toolbar-height: 80px;

$app-color: #000;

body {
    background-color: $app-color;
}

.view-canvas-wrapper::before {
    position: absolute;
    background: url(../images/allsky-map.svg) no-repeat top left;
    width: 100%;
    height: 100%;
    display: block;
    content: ' ';
    background-size: contain;
}

:has(input[name='map']:checked) .view-canvas-wrapper::before {
    opacity: 1;
}

.view-canvas-wrapper::before {
    transition: opacity 1s linear;
    opacity: 0;
}

#app {
    background-color: $app-color;

    font-family: "Roboto", "RobotoFallback", sans-serif;
    font-weight: 400;
    font-size: 14px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-x: hidden;
    max-width: $app-width;
    margin: 0 auto;
    justify-content: space-around;
    gap: 30px;

    * {
        user-select: none;
    }

    section,
    main,
    .view canvas {
        width: 100%;
        max-width: $view-size;
    }

    .view-canvas-wrapper {
        position: relative;
    }

    aside {
        display: block;
        flex-shrink: 0;
        // align with top of toolbar
        margin-top: 20px;

        &.hidden {
            display: none;
        
        }
    }

    .usage {
        color: white;
        width: 100%;
        max-width: 250px;

        ul {
            padding-left: 1em;
        }

        h2 {
            font-size: 1em;
        }
    }

    nav {
        width: 100%;
        height: $toolbar-height;
        padding: 5px 20px 20px 0;

        .calendar-wrapper {
            background-color: $app-color;
        }

        .toolbar-date {
            width: 100px;
            text-align: center;
        }

        .calendar-wrapper {
            position: absolute;
            z-index: 2;
            padding: 20px;

        }
    }    

    main {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        
        &:focus {
            outline: none;
        }

        &::before {
            z-index: 2;
            top: 0;
            left: 0;
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            background-color: $app-color;
            display: none;
        }

        &::after {
            color: #fff;
            z-index: 2;
            position: absolute;
            bottom: 50%;
            left: 0;
            content: "Loading ... " attr(data-loading) "%";
          
            width: 100%;
            text-align: center;
            display: none;
        }

        &[data-loading] {
            &::before, &::after {
                display: block;
                // cursor: wait;
            }
        }

        .toolbar {
            flex-basis: $base-size;
        }


        .view {
            display: flex;
            height: 560px;
            max-width: $view-size;
            flex-direction: row;
            position: relative;

            .view-layout {
                width: 100%;
                height: 100%;
            }

            .view-controls-layout {
                z-index: 1;
                position: absolute;
                width: 100%;
                height: 0;

                overflow: visible;

                .view-prev-wrapper, .view-next-wrapper {
                    position: absolute;
                    width: 50px;
                    height: 50px;

                    > * {
                        width: 100%;
                        height: 100%;
                    }
                }
                
                .view-prev-wrapper {
                    left: 0;
                }
    
                .view-next-wrapper {
                    right: 0;
                }
            }
        }





        .timeline {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-width: $view-size;

            overflow-x: scroll;
            overflow-y: hidden;
            scroll-snap-type: x mandatory;
            cursor: grab;

            &.grab {
                cursor: ew-resize;
                // user-select: none;
            }

            @include scrollbars(10px, rgba($color: #fff, $alpha: 0.3), rgba($color: #fff, $alpha: 0.2));

          

            .timeline-thumbs-wrapper {
                position: absolute;
                bottom: 0;


                .timeline-thumbs {
                    height: $base-size;
                    display: flex;       
                    flex-direction: row;
                    flex-wrap: nowrap;
                    
                    &.with-indices {
                        .timeline-thumb-item-wrapper {
                            &::after {
                                display: block;
                            }
                        }
                    }
    
                    .timeline-thumb-item-wrapper {
                        padding: 4px;
                        scroll-snap-align: start;
                        position: relative;
                        height: $base-size;
                        width: $base-size;

                        .timeline-thumb-item {
                            height: 100%;
                            width: 100%;
                        }

                        &::after {
                            display: none;
                    
                            position: absolute;
                            top: 0;
                            left: 0;
                            background-color: rgba($color: $app-color, $alpha: 0.7);
                            color: rgba($color: #fff, $alpha: 0.9);
                            font-size: 11px;
                            content: attr(data-time);
                        }

                        &::before {
                            display: none;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            background-color: rgba($color: #fff, $alpha: 0.2);
                            content: '';
                        }
    
                        &:hover {
                            z-index: 1;
                            cursor: pointer;

                            &::after {
                                display: block;
                            }
                            
                        }    
    
                        &.selected-tile {
                            &::before {
                                display: block;
                            }

                            &::after {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        &.grid-view {
            .view {
                display: none;
            }
            
            .timeline {
                position: static;
                cursor: unset;
                overflow-y: visible;
                overflow-x: hidden;

                .timeline-thumbs-wrapper {
                    position: static;
                    height: 100%;

                    .timeline-thumbs {
                        height: auto;
                        flex-wrap: wrap;
                    }
                }
            }
        }
    }
}	

.logo {
    width: auto;
    height: 60px;
    margin: 30px 0;
}

@media (max-width: $view-size) {
    .dx-toolbar-center {
        display: none;
    }
}
