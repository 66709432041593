@import url(../../../../node_modules/devextreme/dist/css/dx.common.css);
@import url(../../../../node_modules/devextreme/dist/css/dx.material.blue.dark.compact.css);

.dx-calendar {
    background-color: unset;

    thead {
        display: none;
    }
    
    .dx-calendar-today {
        font-weight: unset;
    }

    .dx-button {
        .dx-icon {
            color: #fff !important;
        
        }    
        &.dx-state-hover {
            background-color:rgba(255,255,255,.1);;
        }
        &.dx-state-active {
            background-color:rgba(255,255,255,.3);;
        }
    }
    
}

.dx-calendar-navigator {
    .dx-button {
        color: unset;
    } 
}

.dx-calendar-cell.dx-calendar-selected-date span, 
.dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today span {
    color: #000;
    background-color: #fff;
}


.dx-toolbar {
    background-color: unset;

    .dx-button-text {
        // text-transform: unset;
    }

    .dx-toolbar-item-content {
        user-select: none;
    }
}

.view-controls-layout {
    .dx-button {
        &.dx-state-hover,  &.dx-state-active   {
            .dx-icon {
                transition: font-size 0.1s line-height 0.1s;
                width: 22px;
                height: 22px;
                background-size: 22px 22px;
                font-size: 22px;
                line-height: 22px;
            }
        
        }
    }
}