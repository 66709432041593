* {
    box-sizing: inherit;

    &:before, &:after {
        box-sizing: inherit;
    }
}

html {
    box-sizing: border-box;
}
